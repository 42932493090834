exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-archives-tsx": () => import("./../../../src/pages/archives.tsx" /* webpackChunkName: "component---src-pages-archives-tsx" */),
  "component---src-pages-blog-archives-tsx": () => import("./../../../src/pages/blogArchives.tsx" /* webpackChunkName: "component---src-pages-blog-archives-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-claim-success-tsx": () => import("./../../../src/pages/claim-success.tsx" /* webpackChunkName: "component---src-pages-claim-success-tsx" */),
  "component---src-pages-contact-customer-service-tsx": () => import("./../../../src/pages/contact/customer-service.tsx" /* webpackChunkName: "component---src-pages-contact-customer-service-tsx" */),
  "component---src-pages-contact-technical-support-tsx": () => import("./../../../src/pages/contact/technical-support.tsx" /* webpackChunkName: "component---src-pages-contact-technical-support-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-gov-incentives-tsx": () => import("./../../../src/pages/gov-incentives.tsx" /* webpackChunkName: "component---src-pages-gov-incentives-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-my-cart-tsx": () => import("./../../../src/pages/my-cart.tsx" /* webpackChunkName: "component---src-pages-my-cart-tsx" */),
  "component---src-pages-order-confirmation-tsx": () => import("./../../../src/pages/order-confirmation.tsx" /* webpackChunkName: "component---src-pages-order-confirmation-tsx" */),
  "component---src-pages-order-status-tsx": () => import("./../../../src/pages/order-status.tsx" /* webpackChunkName: "component---src-pages-order-status-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-promotions-tsx": () => import("./../../../src/pages/promotions.tsx" /* webpackChunkName: "component---src-pages-promotions-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-return-policy-tsx": () => import("./../../../src/pages/return-policy.tsx" /* webpackChunkName: "component---src-pages-return-policy-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-shipping-policy-tsx": () => import("./../../../src/pages/shipping-policy.tsx" /* webpackChunkName: "component---src-pages-shipping-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-test-reports-tsx": () => import("./../../../src/pages/test-reports.tsx" /* webpackChunkName: "component---src-pages-test-reports-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-pages-warranty-registration-tsx": () => import("./../../../src/pages/warranty-registration.tsx" /* webpackChunkName: "component---src-pages-warranty-registration-tsx" */),
  "component---src-pages-where-to-buy-tsx": () => import("./../../../src/pages/where-to-buy.tsx" /* webpackChunkName: "component---src-pages-where-to-buy-tsx" */),
  "component---src-templates-accessory-archives-tsx": () => import("./../../../src/templates/AccessoryArchives.tsx" /* webpackChunkName: "component---src-templates-accessory-archives-tsx" */),
  "component---src-templates-appliance-archives-tsx": () => import("./../../../src/templates/ApplianceArchives.tsx" /* webpackChunkName: "component---src-templates-appliance-archives-tsx" */),
  "component---src-templates-appliance-page-tsx": () => import("./../../../src/templates/AppliancePage.tsx" /* webpackChunkName: "component---src-templates-appliance-page-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/BlogPage.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-category-archives-tsx": () => import("./../../../src/templates/CategoryArchives.tsx" /* webpackChunkName: "component---src-templates-category-archives-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/Faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-manuals-tsx": () => import("./../../../src/templates/Manuals.tsx" /* webpackChunkName: "component---src-templates-manuals-tsx" */),
  "component---src-templates-parts-tsx": () => import("./../../../src/templates/Parts.tsx" /* webpackChunkName: "component---src-templates-parts-tsx" */),
  "component---src-templates-single-page-tsx": () => import("./../../../src/templates/SinglePage.tsx" /* webpackChunkName: "component---src-templates-single-page-tsx" */),
  "component---src-templates-venting-archives-tsx": () => import("./../../../src/templates/VentingArchives.tsx" /* webpackChunkName: "component---src-templates-venting-archives-tsx" */),
  "component---src-templates-warranty-tsx": () => import("./../../../src/templates/Warranty.tsx" /* webpackChunkName: "component---src-templates-warranty-tsx" */)
}

